<template>
    <img
            class="
                w-full
                max-w-md
                md:max-w-lg
                lg:max-w-xl
                xl:max-w-2xl
                2xl:max-w-3xl
                -mt-24"
            src="../assets/Logo_MakeKing.svg" alt="Logo MakeKing">
</template>

<script setup></script>

<style scoped>
</style>
